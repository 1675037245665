import React, { useContext, useEffect } from "react";
import styled from "styled-components";
import { Box, Button, Text, Title } from "../../components/Core";
import { device } from "../../utils";
import { Col, Container, Row } from "react-bootstrap";
import GlobalContext from "../../context/GlobalContext";
import FullScreenLoader from "../../components/FullScreenLoader";
import AdditionalSupportInformation from "../../sections/support/additionalSupportInformation";
import calendarError from "../../assets/image/svg/youfibre-calendar-error.svg";
import { Link, navigate } from "gatsby";
import Seo from "../../components/Seo/Seo";

const BigTitle = styled(Title)`
  font-size: 2.8em;
`
const BoxStyled = styled(Box)`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
`
const BoxInner = styled(Box)`
  margin-top: -65px;
  min-height: 100vh;
`
const FormStyled = styled.div`
  padding: 40px 30px;
  @media ${device.sm} {
    padding: 53px 58px 50px;
  }
  border-radius: 10px;
  width: 100%;
`

const AppointmentError = (props) => {
    const gContext = useContext(GlobalContext)
    let orderId = new URLSearchParams(props.location.search).get("orderid");

    useEffect(() => {
        if (gContext.fullScreenLoader) gContext.hideFullScreenLoader()
    }, [])

    /* Disallow direct access without orderid URL parameter */
    useEffect(() => {
        if (!orderId) {
            navigate('/')
        }
    }, [orderId])


    return (
        <div>
            <Seo page="appointment-error" />
            <FullScreenLoader title="Processing data ..." />
            {
                orderId ?
                    <BoxStyled bg="#f7f8fa">
                        <BoxInner className="d-flex align-items-center">
                            <Container>
                                <Box my="150px" mx="auto">
                                    <FormStyled>
                                        <Row className="justify-content-center">
                                            <Col sm={12} lg={11} className="text-center mb-5">
                                                <img src={calendarError} alt="Appointment OK"
                                                    style={{ maxWidth: '80px' }} />
                                            </Col>
                                            <Col sm={12} lg={11} className="mx-auto">
                                                <div className="mb-3 pb-2">
                                                    <BigTitle className="mt-3 mb-2 text-center">
                                                        Your installation has already been scheduled.
                                                    </BigTitle>
                                                    <Text variant="card" className="text-center mb-5">
                                                        But if you feel like changing things, let us know.
                                                    </Text>
                                                </div>
                                            </Col>
                                            <Col sm={12} lg={4} className="mx-auto">
                                                <Link to={"/"}>
                                                    <Button width="100%" borderRadius={10} className="mx-auto">
                                                        Return to Homepage
                                                    </Button>
                                                </Link>
                                            </Col>
                                        </Row>
                                    </FormStyled>
                                </Box>
                                <Box my="120px" mx="auto">
                                    <AdditionalSupportInformation
                                        title="Something's not quite right?"
                                        subtitle="Contact our team and we will help you sort it out in no time."
                                    />
                                </Box>
                            </Container>
                        </BoxInner>
                    </BoxStyled>
                    :
                    <></>
            }
        </div>
    )
}

export default AppointmentError